import React from "react";
import { Stack, useTheme } from "@mui/material";

const NarrowWidth = ({ children }) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        maxWidth: "70%",
        [theme.breakpoints.down("md")]: {
          maxWidth: "100%",
        },
      }}
    >
      {children}
    </Stack>
  );
};

export default NarrowWidth;
