import React from "react";
import { Box, Typography } from "@mui/material";

const ComingSoon = ({ styles }) => (
  <Box
    className="comingSoon"
    sx={{
      background: "#fff",
      py: 1,
      px: 2,
      opacity: 0,
      position: "absolute",
      zIndex: 3,
      transition: "all .8s cubic-bezier(.215,.61,.355,1)",
      ...styles,
    }}
  >
    <Typography
      variant="span"
      color="text.dark"
      textTransform="uppercase"
      fontWeight={800}
      fontStyle="italic"
      sx={{ position: "relative", top: 1 }}
    >
      Coming Soon!
    </Typography>
  </Box>
);

export default ComingSoon;
