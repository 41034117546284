import React from "react";
import { Stack } from "@mui/material";
import SectionHeader from "../atoms/SectionHeader";
import ComingSoon from "components/Typography/ComingSoon";
import Skill from "../atoms/Skill";

const Skills = ({ skills }) => (
  <Stack>
    <SectionHeader>Skills</SectionHeader>
    {skills.length === 0 ? (
      <ComingSoon />
    ) : (
      skills.map((skill, i) => <Skill key={`SKILL_${i}`} skill={skill} />)
    )}
  </Stack>
);

export default Skills;
