import { Tooltip } from "@mui/material";
import React from "react";
import CustomLink from "components/CustomLink/CustomLink";

const LinkedWrapper = ({ children, id }) =>
  id == null ? (
    <Tooltip title="Coming Soon!">{children}</Tooltip>
  ) : (
    <CustomLink to={`/character/${id}`}>{children}</CustomLink>
  );

export default LinkedWrapper;
