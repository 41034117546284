import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Video from "../atoms/Video";
import ClippedWrapper from "../atoms/ClippedWrapper";
import FallbackImage from "../atoms/FallbackImage";

const Jumbotron = ({ character }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [videoLoaded, setVideoLoaded] = useState(false);

  return (
    <ClippedWrapper>
      <FallbackImage id={character.id} />
      {!smDown && (
        <Video
          id={character.id}
          videoLoaded={videoLoaded}
          setVideoLoaded={setVideoLoaded}
        />
      )}
    </ClippedWrapper>
  );
};

export default Jumbotron;
