import { IconButton, Stack } from "@mui/material";
import React from "react";
import { FaTwitter } from "react-icons/fa";
import { SIPHER_TECH_TWITTER } from "config";

const Socials = ({ color = "#fff" }) => {
  return (
    <Stack>
      <IconButton
        href={SIPHER_TECH_TWITTER}
        target="_blank"
        sx={{
          svg: {
            color,
          },
        }}
      >
        <FaTwitter />
      </IconButton>
    </Stack>
  );
};

export default Socials;
