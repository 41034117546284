import React from "react";
import { Typography } from "@mui/material";
import { SIPHER_TECH_COMPANY_NAME } from "config";

const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <Typography
      variant="span"
      fontWeight="600"
      fontSize={12}
      color="text.muted"
    >
      © {year} {SIPHER_TECH_COMPANY_NAME}
    </Typography>
  );
};

export default Copyright;
