import React from "react";
import { Typography } from "@mui/material";

const ComingSoon = () => (
  <Typography fontWeight={800} fontStyle="italic" variant="body2" fontSize={20}>
    Coming Soon!
  </Typography>
);

export default ComingSoon;
