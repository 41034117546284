import { Box, Container, useTheme } from "@mui/material";
import React from "react";

const Page = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        py: 20,
        [theme.breakpoints.down("sm")]: {
          py: 10,
        },
      }}
    >
      <Container>{children}</Container>
    </Box>
  );
};

export default Page;
