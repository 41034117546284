import React from "react";
import { Box, useTheme } from "@mui/material";
import { addAlphaToHex } from "helpers/colors";

const ModelImage = ({ character }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        top: -60,
        mb: "-40px",
        img: { width: "100%", position: "relative", zIndex: 3 },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          top: "-10%",
          left: "50%",
          transform: "translateX(-50%)",
          width: "30vw",
          height: "30vw",
          background: addAlphaToHex(theme.palette.colors.primary, 0.5),
          filter: "blur(20vw)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          zIndex: 2,
          top: "-10%",
          right: "10%",
          width: "15vw",
          height: "15vw",
          background: addAlphaToHex(theme.palette.colors.tertiary, 0.8),
          filter: "blur(10vw)",
        }}
      />
      <img
        src={`/images/characters/3d/${character.species.toLowerCase()}-${character.subSpecies.toLowerCase()}.png`}
        alt="Character model"
      />
    </Box>
  );
};

export default ModelImage;
