import { SIPHER_TECH_DESCRIPTION } from "config";
import { SIPHER_TECH_TITLE } from "config";
import { createCharacterName } from "helpers/display";
import { characters } from "./characters";

const augmentTitle = (title) => `${title} | ${SIPHER_TECH_TITLE}`;

const seoForCharacters = characters.reduce((obj, c) => {
  if (c.id != null) {
    obj[c.id] = {
      title: augmentTitle(createCharacterName(c.species, c.subSpecies)),
      description: c.bio.join(" "),
    };
  }
  return obj;
}, []);

export const seoMeta = {
  characters: {
    title: augmentTitle("Characters"),
    description:
      "Learn more about the playable characters in Sipheria, including their skills and abilities in battle.",
  },
  terms: {
    title: augmentTitle("Terms and Conditions"),
    description: SIPHER_TECH_DESCRIPTION,
  },
  ...seoForCharacters,
};
