import React from "react";

const CharacterImage = ({ slug, name }) =>
  slug == null ? (
    <img
      src={`/images/characters/square/hidden.png`}
      alt="Hidden sub-species"
      className="characterImage"
    />
  ) : (
    <img
      src={`/images/characters/square/${slug}.png`}
      alt={name}
      className="characterImage"
    />
  );

export default CharacterImage;
