import React from "react";
import { Box } from "@mui/material";

const FallbackImage = ({ id }) => (
  <Box
    sx={{
      backgroundImage: `url('/images/characters/video-stills/${id}.jpg')`,
      backgroundSize: "cover",
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    }}
  />
);

export default FallbackImage;
