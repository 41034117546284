import React from "react";
import { List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import HeadingUnderlined from "components/Typography/HeadingUnderlined";
import Label from "components/Typography/Label";
import CustomLink from "components/CustomLink/CustomLink";
import { OPENSEA_COLLECTIONS } from "config";
import NarrowWidth from "../atoms/NarrowWidth";

const NFTs = () => {
  return (
    <Stack gap={4}>
      <NarrowWidth>
        <HeadingUnderlined variant="h2">What are NFTs?</HeadingUnderlined>
        <Typography variant="body1">
          Every playable character in Sipheria is an Non-Fungible Token (NFT).
          You can think of NFTs like digital collectibles where you, the
          collector, have complete ownership and control. If you like, you can
          buy, sell or trade your NFT characters with other players on the open
          market.
        </Typography>
      </NarrowWidth>

      <Stack gap={1}>
        <Label>Browse Markets:</Label>

        <List>
          <MarketLink collection={OPENSEA_COLLECTIONS.inu} />
          <MarketLink collection={OPENSEA_COLLECTIONS.neko} />
          <MarketLink collection={OPENSEA_COLLECTIONS.buru} />
        </List>
      </Stack>
    </Stack>
  );
};

const MarketLink = ({ collection }) => {
  return collection.url == null ? (
    <ListItem>
      <ListItemText>{`${collection.species} – ${collection.name}`}</ListItemText>
    </ListItem>
  ) : (
    <ListItem>
      <ListItemText>
        <CustomLink
          href={collection.url}
        >{`${collection.species} – ${collection.name}`}</CustomLink>
      </ListItemText>
    </ListItem>
  );
};

export default NFTs;
