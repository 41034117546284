import React from "react";
import { a11yProps } from "helpers/accessibility";
import { Box, Stack, Tab, Tabs, useTheme } from "@mui/material";
import Skills from "../molecules/Skills";
import Bio from "../atoms/Bio";
import Weapons from "../molecules/Weapons";
import Species from "../molecules/Species";
import Family from "./Family";

const InfoPanel = ({ character }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "40vw",
        [theme.breakpoints.down("md")]: {
          width: "90vw",
        },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Character Details"
      >
        <Tab label="Character" {...a11yProps("character-details", 0)} />
        <Tab label="Story" {...a11yProps("character-details", 1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Bio bio={character.bio} />

        <Skills skills={character.skills} />

        <Weapons />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Species species={character.species} />

        <Family species={character.species} />
      </TabPanel>
    </Box>
  );
};

const TabPanel = (props) => {
  const theme = useTheme();

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`character-details-${index}`}
      aria-labelledby={`character-details-${index}`}
      {...other}
    >
      {value === index && (
        <Stack
          gap={1}
          sx={{
            p: 3,
            background: theme.palette.colors.black900,
            border: `4px solid ${theme.palette.colors.primary}`,
            boxShadow: `4px 4px ${theme.palette.colors.secondary}`,
          }}
        >
          {children}
        </Stack>
      )}
    </div>
  );
};

export default InfoPanel;
