import { Container, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import CustomLink from "../../CustomLink/CustomLink";
import Socials from "../../Socials/Socials";
import Copyright from "../atoms/Copyright";
import TriballyBadge from "components/TriballyBadge/TriballyBadge";
import { TRIBALLY_SLOGAN } from "config";

const MetaBar = () => {
  const theme = useTheme();
  return (
    <Stack sx={{ py: 2, img: { height: 24 } }}>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction={{ xs: "column", sm: "column", md: "row" }}
            alignItems="center"
            gap={2}
          >
            <CustomLink to="/">
              <img
                src="/images/sipher-tech-logo-outline.png"
                alt="Sipher Tech Logo"
              />
            </CustomLink>

            <Stack direction="row" gap={2}>
              <Copyright />
              <Typography
                variant="body2"
                fontSize={11}
                mb={0}
                color="text.muted"
              >
                ▫
              </Typography>
              <Typography variant="body2" fontSize={11} mb={0}>
                <CustomLink
                  underline="none"
                  to="/terms-and-conditions"
                  style={{
                    color: theme.palette.text.muted,
                  }}
                >
                  Terms and Conditions
                </CustomLink>
              </Typography>
              <Typography variant="body2" fontSize={11} mb={0}>
                <CustomLink
                  underline="none"
                  to="/privacy-policy"
                  style={{
                    color: theme.palette.text.muted,
                  }}
                >
                  Privacy Policy
                </CustomLink>
              </Typography>
            </Stack>
            <TriballyBadge
              width={200}
              title={TRIBALLY_SLOGAN}
              alt={TRIBALLY_SLOGAN}
            />
          </Stack>
          <Socials />
        </Stack>
      </Container>
    </Stack>
  );
};

export default MetaBar;
