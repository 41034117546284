import { Stack, Typography } from "@mui/material";
import React from "react";

const Name = ({ species, subSpecies, fontSize = 28, dark = true }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={(1 / 28) * fontSize}
      sx={{
        "*": {
          lineHeight: 1,
        },
      }}
    >
      <Species fontSize={fontSize} dark={dark}>
        {species}
      </Species>
      <SubSpecies fontSize={fontSize} dark={dark}>
        {subSpecies}
      </SubSpecies>
    </Stack>
  );
};

const Species = ({ children, fontSize, dark }) => (
  <Typography
    variant="span"
    fontSize={fontSize}
    color={dark ? "text.dark" : "text.bright"}
    marginBottom={0}
    textTransform="uppercase"
    fontFamily="Koulen, sans-serif"
    fontWeight={400}
    sx={{
      position: "relative",
      bottom: (0.5 / 28) * fontSize,
      textShadow:
        dark === false ? `0px 0px ${(5 / 28) * fontSize}px #000` : "none",
    }}
  >
    {children}
  </Typography>
);

const SubSpecies = ({ children, fontSize, dark }) => (
  <Typography
    variant="span"
    fontSize={fontSize}
    fontFamily="Industry, sans-serif"
    textTransform="uppercase"
    fontWeight={800}
    fontStyle="italic"
    color={dark ? "text.dark" : "text.bright"}
    marginBottom={0}
    sx={{
      textShadow:
        dark === false ? `0px 0px ${(5 / 28) * fontSize}px #000` : "none",
    }}
  >
    {children}
  </Typography>
);

export default Name;
