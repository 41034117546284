import { Stack } from "@mui/material";
import React from "react";
import ComingSoon from "components/Typography/ComingSoon";
import SectionHeader from "../atoms/SectionHeader";

const Weapons = () => {
  return (
    <Stack>
      <SectionHeader>Weapons</SectionHeader>
      <ComingSoon />
    </Stack>
  );
};

export default Weapons;
