import { Stack } from "@mui/material";
import React, { useState } from "react";
import Explore from "components/Explore/Explore";
import FilterButtons from "./molecules/FilterButtons";
import Intro from "./molecules/Intro";
import NFTs from "./molecules/NFTs";
import CharacterGallery from "./organisms/CharacterGallery";

const Characters = () => {
  const [filter, setFilter] = useState("All");
  return (
    <Stack gap={{ xs: 16, sm: 16, md: 20 }}>
      <Stack gap={8}>
        <Intro />
        <FilterButtons filter={filter} setFilter={setFilter} />
        <CharacterGallery filter={filter} />
      </Stack>
      <NFTs />
      <Explore />
    </Stack>
  );
};

export default Characters;
