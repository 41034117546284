import { Box, Stack, useTheme } from "@mui/material";
import React from "react";
import InfoPanel from "./InfoPanel";
import ModelImage from "../atoms/ModelImage";
import Rarity from "../atoms/Rarity";
import { createCharacterName } from "helpers/display";
import BackButton from "../atoms/BackButton";

const Profile = ({ character }) => {
  const theme = useTheme();

  return (
    <Box sx={{ position: "relative" }}>
      <BackgroundImage />
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: character.species === "Neko" ? 0 : -50,
          opacity: 0.2,
          width: "100%",
          img: {
            width: "100%",
          },
        }}
      >
        <img
          src={`/images/characters/names/${character.id}.png`}
          alt={createCharacterName(character.species, character.subSpecies)}
        />
      </Box>
      <Stack
        direction={{ xs: "column-reverse", sm: "column-reverse", md: "row" }}
        gap={{ xs: 8, sm: 8, md: 6 }}
        justifyContent={{
          xs: "flex-start",
          sm: "flex-start",
          md: "space-between",
        }}
        sx={{
          py: 16,
          px: "8vw",
          position: "relative",
          zIndex: 2,
          [theme.breakpoints.down("lg")]: {
            px: "5vw",
          },
          [theme.breakpoints.down("sm")]: {
            py: 8,
            px: "2vw",
          },
        }}
      >
        <Stack gap={8} alignItems="center">
          <InfoPanel character={character} />

          <BackButton />
        </Stack>

        <Stack gap={2} alignItems="center">
          <ModelImage character={character} />

          <Rarity
            species={character.species}
            subSpecies={character.subSpecies}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

const BackgroundImage = () => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 0,
      img: {
        width: "100%",
        top: "20vh",
      },
    }}
  >
    <img src="/images/bg.jpg" alt="Background" />
  </Box>
);

export default Profile;
