export const speciesBios = {
  inu: [
    "The first 10,000 Siphers are part of the 1st Fleet, the 'Sipherian Surge', and they belong to the Genesis race, INU, who were created by the Mad Scientist. They are the product of animal CRISPR-genome extraction combined with ethereal elements. All INU characters have unique looks, sub-racial traits and hidden abilities.‌",
    "The Sipherian Surge was given a very challenging mission - to establish the first World Block in Sipheria, code named Genesis. Being the first adventurers on this quest will grant the first 10,000 INU special privileges that allows them to acquire valuable nodes and tiles, the equivalent of land in Sipheria on world block Genesis.",
  ],
  neko: [
    "The second 10,000 Siphers, part of the 'Sipherian Flash' collection. They are the second Genesis race, created by the Mad Scientist and his colleagues. All NEKO characters are unique, and each is a part of four sub-races.",
  ],
  buru: [],
};

export const characters = [
  {
    id: "inu-canis",
    species: "Inu",
    subSpecies: "Canis",
    bio: [
      "Canis are volunteers that undergone training at the Sipheria Academy prior joining the 1st Fleet. As a result, they possess 5 core Skills available to INU race",
      "As the origin specie of Inu, Canis is capable of a wide range of skills and abilities, allowing them to be the most versatile sub-race yet.",
    ],
    video: "/images/characters/inu.mp4",
    fallbackBanner: "neko-banner",
    skills: [
      { name: "Pure Breed", effect: "Passive Skill: Speed +1 movement" },
      {
        name: "Hair of the Dog",
        effect: "Active Skill: Recover HP and cure all status effects",
      },
      {
        name: "Doge this",
        effect: "Active Skill: Increase attack speed for 1.5s",
      },
    ],
  },
  {
    id: "inu-cyborg",
    species: "Inu",
    subSpecies: "Cyborg",
    bio: [
      "Cyborgs are the successful mechanical augmentations on the original INU by the Mad Scientists. They are able to upgrade their mechanical parts to unlock new skills at the Laboratory to be used in Combat.",
      "As the 'augmented' adventurers of 1st Fleet - Sipherian Surge, they come fully equipped with the 3 Skills specific to their sub-race.",
    ],
    video: "/images/characters/inu.mp4",
    fallbackBanner: "neko-banner",
    skills: [
      {
        name: "Super Strength",
        effect: "Passive Skill - increase damage dealt using Melee Weapons",
      },
      {
        name: "Cyber-charge",
        effect:
          "Active Skill - Cybernetic charge a Weapon to increase attack range",
      },
      {
        name: "Grappling Hook",
        effect:
          "Active Skill - Hook a character close or hook to an object to pull them towards the object",
      },
    ],
  },
  {
    id: "inu-bioz",
    species: "Inu",
    subSpecies: "BioZ",
    bio: [
      "BioZ are the successful biological experimentations on the original INU by the Mad Scientists. They are able to upgrade their biological parts to unlock new skills at the Laboratory to be used in Combat.",
      "As the 'lucky' experiments of 1st Fleet Sipherian Surge, they come fully equipped with the 3 Skills specific to their sub-race.",
    ],
    video: "/images/characters/inu.mp4",
    fallbackBanner: "neko-banner",
    skills: [
      {
        name: "Regeneration",
        effect: "Active Skill- regenerate a damaged body part, recovering HP",
      },
      {
        name: "Hungry, Must Feed",
        effect:
          "Active Skill - lifesteal attack that damages an enemy while recovering a portion of that in HP",
      },
      {
        name: "Infectious Disease",
        effect:
          "Passive Skill - attack modifier that cause damage over time to enemy(ies)",
      },
    ],
  },
  {
    id: "inu-cosmic",
    species: "Inu",
    subSpecies: "Cosmic",
    bio: [
      "Cosmic is a rare meta-occurrence as the fleet enter cryptoverse. A small selection of INUs were transformed in Cosmic beings with never before seen powers. They will be able to collect scattered cosmic energy through expeditions across Sipheria to unlock new skills to be used in Combat.",
      "As the first cosmic beings, they come come fully equipped with 3 skills specific to their sub-race",
    ],
    video: "/images/characters/inu.mp4",
    fallbackBanner: "neko-banner",
    skills: [
      {
        name: "Telekinesis Push",
        effect:
          "Active Skill - ability to push enemy characters by force of will further away",
      },
      {
        name: "Time Warp",
        effect: "Active Skill - ability to go back in time 2s",
      },
      {
        name: "Ethereal",
        effect: "Active Skill - ability to go invisible for 1.5s",
      },
    ],
  },
  {
    id: "neko-felis",
    species: "Neko",
    subSpecies: "Felis",
    bio: [
      "Felis make up the majority of the Neko race, those chosen were sent under the banner of the Sipherian Flash, to reinforce the 1st Fleet in combat and exploration. All Felis possess strong and unique capabilities for flashy and high speed combat, though the origin of their ability to survive is still an enigma. Some even say that they've got nine lives.",
    ],
    video: undefined,
    fallbackBanner: "neko-banner",
    skills: [],
  },
  {
    id: "neko-synthetic",
    species: "Neko",
    subSpecies: "Synthetic",
    bio: [
      "Synthetics are full amalgamations of flesh and machine, created to leverage the highest capabilities hi-tech machineries have to offer. Possessing heightened levels of intellect through their ability of rapid autonomous learning and physical resistances have given them a massive technological advantage on the field.",
    ],
    video: undefined,
    fallbackBanner: "neko-banner",
    skills: [],
  },
  {
    id: "neko-phasewalker",
    species: "Neko",
    subSpecies: "Phasewalker",
    bio: [
      "Phasewalkers are results of inter-dimensional portal experimentations by the mad scientists. However their failure to stabilize the coordinates trapped unlucky ones in unknown dimensions. Only a few made it back from “the other worlds” to the Sipherian Flash fleet, having their beings transformed with the ability to phase back and forth between alternate dimensions.",
    ],
    video: undefined,
    fallbackBanner: "neko-banner",
    skills: [],
  },
  {
    id: "neko-crystalis",
    species: "Neko",
    subSpecies: "Crystalis",
    bio: [
      "A rare wave of cosmic meteors crashed into the Sipherian Flash, piercing some members with unknown energy shards, altering their DNAs into tetrahedrally bonded structures. The few chance survivors acquired hardened adamantine body parts that are highly resistant to physical and electrical forces, whilst granting them the ability to generate and manipulate crystal elements of similar nature.",
    ],
    video: undefined,
    fallbackBanner: "neko-banner",
    skills: [],
  },
  {
    id: undefined,
    species: "Buru",
    subSpecies: "Inferno",
    bio: [],
    video: undefined,
    fallbackBanner: "",
    skills: [],
  },
  {
    id: undefined,
    species: "Buru",
    subSpecies: "Ancient",
    bio: "",
    video: undefined,
    fallbackBanner: "",
    skills: [],
  },
  {
    id: undefined,
    species: "Buru",
    subSpecies: "Mecha",
    bio: [],
    video: undefined,
    fallbackBanner: "",
    skills: [],
  },
  {
    id: undefined,
    species: "Buru",
    subSpecies: "Tribal",
    bio: [],
    video: undefined,
    fallbackBanner: "",
    skills: [],
  },
];
