import { Box, Stack } from "@mui/material";
import React from "react";
import MetaBar from "./organisms/MetaBar";

const Footer = () => {
  return (
    <Stack>
      <Box
        sx={{
          height: 260,
          width: "100%",
          backgroundImage: 'url("/images/sipher/environment/enviroment1.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      />
      <MetaBar />
    </Stack>
  );
};

export default Footer;
