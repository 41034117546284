import React from "react";
import { Box } from "@mui/material";
import { Routes, Route } from "react-router";
import { useLocation } from "react-router";
import ScrollToTop from "components/Utility/ScrollToTop";
import Navigation from "components/Navigation/Navigation";
import Footer from "components/Footer/Footer";
import Page from "components/Page/Page";
import Characters from "pages/Characters/Characters";
import SEO from "components/SEO/SEO";
import { seoMeta } from "data/seo";
import { prettifySlug } from "helpers/display";
import Character from "pages/Character/Character";
import { characters } from "data/characters";
import TermsAndConditions from "pages/About/TermsAndConditions";
import { SIPHER_TECH_TITLE } from "config";
import { SIPHER_TECH_DESCRIPTION } from "config";
import PrivacyPolicy from "pages/About/PrivacyPolicy";

const SitePage = ({ children, pageTitle, seoPage }) => (
  <Box sx={{ position: "relative" }}>
    <SEO
      seoTitle={seoPage in seoMeta ? seoMeta[seoPage].title : SIPHER_TECH_TITLE}
      seoDescription={
        seoPage in seoMeta
          ? seoMeta[seoPage].description
          : SIPHER_TECH_DESCRIPTION
      }
    />
    <ScrollToTop />
    <Navigation title={pageTitle} />
    <Box
      sx={{
        position: "absolute",
        zIndex: 0,
        img: {
          width: "100%",
          top: "20vh",
        },
      }}
    >
      <img src="/images/bg.jpg" alt="Background" />
    </Box>
    <Box sx={{ position: "relative", zIndex: 2 }}>{children}</Box>
    <Footer />
  </Box>
);

const CustomRoutes = () => {
  const location = useLocation();
  const [_, page, subPage] = (location.pathname || "").split("/"); // eslint-disable-line
  const validCharacter = characters.find((c) => c.id === subPage);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <SitePage pageTitle="Characters" seoPage="characters">
            <Page>
              <Characters />
            </Page>
          </SitePage>
        }
      />
      <Route
        exact
        path="/characters"
        element={
          <SitePage pageTitle="Characters" seoPage="characters">
            <Page>
              <Characters />
            </Page>
          </SitePage>
        }
      />
      <Route
        exact
        path="/character/*"
        element={
          <SitePage
            pageTitle={validCharacter ? prettifySlug(validCharacter.id) : ""}
            seoPage={subPage}
          >
            <Character id={subPage} />
          </SitePage>
        }
      />
      <Route
        exact
        path="/terms-and-conditions"
        element={
          <SitePage pageTitle="Terms" seoPage="terms">
            <TermsAndConditions />
          </SitePage>
        }
      />
      <Route
        exact
        path="/privacy-policy"
        element={
          <SitePage pageTitle="Privacy Policy" seoPage="privacyPolicy">
            <PrivacyPolicy />
          </SitePage>
        }
      />
    </Routes>
  );
};

export default CustomRoutes;
