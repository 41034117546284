import React from "react";
import { Typography } from "@mui/material";

const TextFromArray = ({ arr }) =>
  arr.map((section, i) => (
    <Typography variant="body2" key={`SECTION_${i}`}>
      {section}
    </Typography>
  ));

export default TextFromArray;
