import React from "react";
import { Box, Stack } from "@mui/material";
import Name from "./Name";

const NamePlate = ({ species, subSpecies, width }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        position: "relative",
        zIndex: 2,
        mt: -3,
      }}
    >
      <Triangle width={width} />
      <Box
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap={1}
        sx={{
          py: (1 / 276) * width,
          background: "#fff",
        }}
      >
        <Name
          species={species}
          subSpecies={subSpecies}
          fontSize={(28 / 276) * width}
        />
      </Box>
    </Stack>
  );
};

const Triangle = ({ width }) => (
  <Box
    sx={{
      width: 0,
      height: 0,
      borderBottom: `${(20 / 276) * width}px solid #fff`,
      borderLeft: `${width}px solid transparent`,
    }}
  />
);

export default NamePlate;
