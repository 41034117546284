import React from "react";
import { Typography } from "@mui/material";

const SectionHeader = ({ children }) => (
  <Typography
    variant="h2"
    fontSize="2rem"
    color="text.primary"
    marginBottom="0.25em"
  >
    {children}
  </Typography>
);

export default SectionHeader;
