import React from "react";
import Page from "components/Page/Page";
import { Stack, Typography } from "@mui/material";
import { H2, P, Note, Section, List, ListItem } from "./Text";

const PrivacyPolicy = () => {
  return (
    <Page>
      <Stack gap={3}>
        <Typography>
          Tribally Games <br /> Privacy and Online Notice
        </Typography>

        <Section>
          <H2>Introduction</H2>
          <Note>Last Updated 30th November 2022</Note>

          <P>
            Tribally Games Ltd BVI (“Tribally Games”) (2097601) understands the
            importance of protecting your personal information and is committed
            to being a responsible custodian of the information you provide to
            us and the information we collect.
          </P>
          <P>
            This privacy and online notice (“Notice”) sets out how and why we
            collect and use your personal information and your rights in
            relation to your personal information, when you interact with us
            whether via the Tribally Games (whose domain names include but are
            not limited to www.tribally.games) website accessible at
            www.tribally.games (“Website”), through the use of our online
            services or platform, through the offering of our Tribally Index
            Tokens (“Tokens”) or otherwise.
          </P>
          <P>
            Tribally refers to a network of gaming-related tools and web
            experiences, acting as a software provider and consultant to games
            developers, and includes independently-operated platforms, websites
            and clients within the ecosystem (our “Services”). For the purpose
            of this Notice, “We”, “Us” or “Our” refers to Tribally Games.
          </P>
          <P>
            This Privacy Notice applies to all Personal Information processing
            activities carried out by us, across platforms, websites, and
            departments of Tribally and Tribally Games.
          </P>
          <P>
            To the extent that you are a client or user of our services, this
            Privacy Notice applies together with any terms of business and other
            contractual documents, including but not limited to any agreements
            we may have with you.
          </P>
          <P>
            We know how important privacy and safety are to you, that is why in
            this Notice we explain what rights you have, which of your data we
            collect and why, and how we use it, so you can be sure it is safe
            with us. Please note that we collect your data primarily as part of
            the registration process of your user account and when you are
            visiting our Website. We have created this Notice in order to
            explain to you the rules of using our platform and our Website. This
            Notice should be read in addition to our Terms & Conditions.
          </P>
          <P>
            In some scenarios Tribally Games will be acting in the capacity of
            data controller and in others as data processor. This Notice applies
            when we act in the capacity of data controller of your personal
            information processed in connection with using our Website, our
            online services or platform and through the offering of Tokens.
          </P>

          <P>
            In addition, we may share your personal data within our group of
            companies, including Tribally Ltd BVI and Tribally Studios Limited
            (collectively known as “Tribally Affiliates”). This may also include
            parents and our ultimate holding companies, affiliates,
            subsidiaries, joint ventures, business units and other companies
            that we acquire in the future after they are made part of Tribally
            Affiliates, who will use your personal data for the purposes
            described in this Notice. Annex 1 contains details of Tribally
            Affiliates.{" "}
          </P>
          <P>
            Please read the following carefully to understand our practices
            around your personal information. If you do not wish for your
            personal information to be used in the manner described within this
            Notice, you should not access our Website and all subdomains,
            utilise our services or participate in the offering of Tokens or
            otherwise provide Tribally Games with your personal information.
          </P>
          <P>Please note the following:</P>
          <List>
            <ListItem>
              <P>
                Unless otherwise defined within this Notice, terms used have the
                same meaning as in the Terms & Conditions.
              </P>
            </ListItem>

            <ListItem>
              <P>
                We do not knowingly collect or ask for data, including personal
                information, from people under the age of 18. If you are under
                the age of 18, please do not send us your data. We delete data
                that we learn is collected from a person under the age of 18
                without verified parental approval.
              </P>
            </ListItem>
          </List>

          <H2>About this Notice</H2>

          <List>
            <ListItem>
              <P>
                Your privacy is important to us, so we have developed this
                Notice to explain how we manage and look after your personal
                information. In this Notice we will specify what data we
                collect, where does it come from and what rights do you have in
                relation to it.
              </P>
            </ListItem>
            <ListItem>
              <P>
                The EU General Data Protection Regulation (“GDPR”) provides
                individuals with several rights including right of access to
                their personal information, called a “Data Subject Access
                Request” (“DSAR”) as well as right to rectification, erasure
                (right to be forgotten) restriction, objection and data
                portability.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We refer in this Notice to “personal information” and “personal
                data”. This refers to personal data as defined in GDPR, namely
                data that directly identifies an individual (for example, name,
                address and contact details, or also that which indirectly
                identifies an individual with less obvious identifiers, such as
                website cookies and internet protocol (IP) addresses, device ID
                or other online identifier). Tribally Games processes a wide
                range of personal data about its customers, players, agents,
                staff, suppliers and other third parties.
              </P>
            </ListItem>
            <ListItem>
              <P>
                Personal data we collect is processed in accordance with the
                law, only for specified, legally permitted purposes.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We make every effort to ensure that the data collected by us is
                substantively correct and adequate in relation to the purposes
                for which it is processed and stored in a form that allows
                identification of persons to whom it relates, no longer than it
                is necessary to achieve the purpose of processing. We also
                maintain the security and confidentiality of the personal data
                collected.
              </P>
            </ListItem>
            <ListItem>
              <P>
                The data collected by us is processed, stored and may be
                transferred to any country within the European Union.
              </P>
            </ListItem>
          </List>

          <H2>How and why, we collect your data?</H2>

          <List>
            <ListItem>
              <P>
                Visiting and using our Website is associated with the need to
                collect and process your personal data.
              </P>
            </ListItem>

            <ListItem>
              <P>
                We process your personal data, in particular, the data you
                provide us with directly when setting up and verifying your
                account with us (this is the data you enter on the registration
                forms provided by us and the data contained within the documents
                you send to us) and the data you generate using our Services
                offered by through our Website (e.g. making purchase/ sale
                transactions/ placing orders etc).
              </P>
            </ListItem>
          </List>

          <H2>Information you have provide to us</H2>

          <List>
            <ListItem>
              <P>
                As part of the registration process, purchasers of Tokens are
                required to submit documents and personal information as
                described in our Know Your Client (KYC) Policy. The requirements
                are set out in more detail in the Policy, and may be subject to
                additional requests by us. However, by way of example, we may
                collect the following information from you:
              </P>
              <List>
                <ListItem>
                  <P>Name;</P>
                </ListItem>
                <ListItem>
                  <P>Date of Birth;</P>
                </ListItem>
                <ListItem>
                  <P>
                    Identification i.e. Passport Number/Driving License number;
                  </P>
                </ListItem>
                <ListItem>
                  <P>Nationality and country of residence;</P>
                </ListItem>
                <ListItem>
                  <P>Full residential address;</P>
                </ListItem>
                <ListItem>
                  <P>Email address;</P>
                </ListItem>
                <ListItem>
                  <P>Telephone/mobile number;</P>
                </ListItem>
                <ListItem>
                  <P>Proof of address and photo ID; and</P>
                </ListItem>
                <ListItem>
                  <P>
                    Your wallet address, any other cryptocurrency wallet address
                    or bank account details and any related transaction details
                    recorded by any blockchain on which the Tokens are based or
                    utilised.
                  </P>
                </ListItem>
              </List>
              <ListItem>
                <P>
                  We need to collect such a wide range of information not only
                  to be able to provide our Services, but also to fulfil legal
                  requirements regarding the obligation to identify the client,
                  to monitor, combat and assess the risks of fraud, money
                  laundering and financing terrorism. Therefore, if you do not
                  provide the data requested during the registration and
                  verification process, or if the data provided proves to be
                  false or inaccurate, or if you object to its processing, we
                  will not be able to continue to provide you with our Services.
                </P>
              </ListItem>
              <ListItem>
                <P>
                  Due to the above-described obligations, in order to verify the
                  accuracy of the data provided by you and to assess the risk of
                  fraud, we also monitor your transaction history by analysing
                  the course, volume, currency and type transactions.
                </P>
              </ListItem>
              <ListItem>
                <P>
                  In addition, if you use our Website, we may collect additional
                  information from you. This may include your personal
                  information (including copies of your identity documents, your
                  residential address and contact details) and your IP address.
                </P>
              </ListItem>
              <ListItem>
                <P>
                  If you wish to use additional Services offered by us, we will
                  process your data which we collect in order to provide our
                  Services, in compliance with their description contained in
                  the Terms and Conditions or provided to you separately. These
                  Services may include among others: newsletters, contests,
                  updates etc.
                </P>
              </ListItem>

              <ListItem>
                <P>
                  We may also collect information that you voluntarily provide
                  to us, such as when you communicate with us via email, on
                  social media or via other channels, when you register for our
                  updates and when you respond to our communication or requests
                  for information. The information you provide may include your
                  name, contact information, title and other personal
                  information about you.
                </P>
              </ListItem>
            </ListItem>
          </List>

          <H2>
            Information collected through the use of cookies and similar
            technologies
          </H2>

          <List>
            <ListItem>
              <P>
                By accessing our Website, we may also automatically collect
                certain data from your browser, including server log information
                (such as login details, IP address and details on the time spent
                on various pages on our Website) and device and browser
                information (such as the type of device, universally unique
                identifiers (‘UUID’), advertising identifiers (‘IDFA’), type of
                browser, operating system, hardware and processor information,
                plug- ins and add-ons).
              </P>
            </ListItem>
            <ListItem>
              <P>
                When you visit the Website, we place cookies on your device
                (e.g. computer hard drive, mobile phone). Cookies are small text
                files that are placed on your device by websites that you visit.
                They are widely used to makes websites work, or work more
                efficiently, as well as to provide information to the owners of
                the Website so they can improve its functionality and
                performance. For example, to enable the Website to load quickly,
                making it easier to move between different pages and remembering
                you have been shown a one-off message.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We use Cookies to remember your preferences and settings, to
                remember information that you may enter online, to generate
                aggregate statistics about how people use our Services, to help
                you share content with others and to improve your experience of
                the Services.
              </P>
            </ListItem>
            <ListItem>
              <P>
                You can choose how Cookies are handled by your device through
                your browser or device settings, including refusing or deleting
                all Cookies. At any point you can change your cookie consent
                preference. If you choose not to receive Cookies, please be
                aware that some areas of our website may not function after you
                have changed your cookie preferences.
              </P>
            </ListItem>
            <ListItem>
              <P>
                Most browsers allow some control of most cookies through the
                browser setting. To find out more about cookies, including how
                to see what cookies have been set and hoe to manage and delete
                them, visit www.allaboutcookies.org.
              </P>
            </ListItem>
          </List>

          <H2>Information collected through third parties</H2>

          <List>
            <ListItem>
              <P>
                We may receive information about you from other sources,
                including third parties that help us update, expand, and analyse
                our records, prevent or detect fraud or other suspicious
                activities and process payments. In addition, our Website also
                includes integrated content or links to content provided by
                third parties (such as live chat, social media content, plug-ins
                and applications).
              </P>
            </ListItem>
          </List>

          <H2>How do we use the information collected?</H2>

          <List>
            <ListItem>
              <P>We may use the information collected in the following way:</P>
            </ListItem>
            <List>
              <ListItem>
                <P>
                  To identify you and conduct KYC and Anti-Money Laundering and
                  other sanctions checks (or to verify those carried out by
                  third party sources);
                </P>
              </ListItem>
              <ListItem>
                <P>
                  to provide you with our Services and other services that you
                  request including, as applicable, supplying, and processing
                  your payment for Tokens and any other services to be provided
                  to you;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  to respond to your enquiries or to any problems that may arise
                  (such as difficulties in purchasing Tokens, navigating our
                  Website or accessing certain services or features);
                </P>
              </ListItem>
              <ListItem>
                <P>
                  to inform you of important changes or developments to our
                  Services or policies and to provide you with technical,
                  support or administrative notifications;
                </P>
              </ListItem>
              <ListItem>
                <P>to make payments to you;</P>
              </ListItem>
              <ListItem>
                <P>
                  with your specific consent, to send you marketing
                  communications, and other information or materials that may be
                  of interest to you or which you have expressed an interest in
                  receiving (and subject to your ability to opt-out from
                  receiving those communications);
                </P>
              </ListItem>
              <ListItem>
                <P>to maintain our list of contacts; </P>
              </ListItem>
              <ListItem>
                <P>
                  to understand how customers, use our Website, including by
                  generating and analysing statistics and user trends, and to
                  evaluate, administer, maintain and improve our Website and
                  other services;
                </P>
              </ListItem>
              <ListItem>
                <P>for internal development of new products or services;</P>
              </ListItem>
              <ListItem>
                <P>
                  for our internal business purposes such as helping to compile
                  statistical data analysis of the pages of the Website you
                  visit;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  to administer any services we provide such as, invoicing and
                  detecting, preventing, and responding to actual or potential
                  fraud, illegal activities, or intellectual property
                  infringement; and
                </P>
              </ListItem>
              <ListItem>
                <P>
                  as we believe reasonably necessary, advisable or appropriate
                  to comply with our legal or regulatory obligations.
                </P>
              </ListItem>
            </List>
            <ListItem>
              <P>
                The above are the purposes for which we process your personal
                information. In most cases the legal basis for the processing is
                that it is in Tribally Games interests which is not overridden
                by your rights and freedoms – we refer to this as “Tribally
                Games legitimate business interests”. In some cases, the legal
                basis is that the processing is necessary to perform a contract
                with you or to comply with a legal or regulatory obligation.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We will only use your personal information for the purposes for
                which we collected it, unless we reasonably consider that we
                need to use it for another reason and that reason is compatible
                with the original purpose. If we need to use your personal
                information for an unrelated purpose, we will notify you and
                will explain the legal basis which allows us to do so.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We may also aggregate personal and other data captured through
                our website so that the data is no longer capable of identifying
                an individual. We reserve the right to use this aggregated
                information for the purposes of improving and enhancing our
                services, generating insights, for use in marketing to other
                users and current and potential partners and otherwise for the
                purposes of our business. Provided that such aggregated data
                does not directly or indirectly identify you as an individual,
                this data is not considered to be personal information for the
                purpose of this Notice.
              </P>
            </ListItem>
            <ListItem>
              <P>
                When you close your account with us, all the data entered is
                deleted from our data base.
              </P>
            </ListItem>
          </List>

          <H2>Who do we share your information with?</H2>

          <List>
            <ListItem>
              <P>
                We may share your personal information we collect with Tribally
                Affiliates, as explained at the beginning of this Notice.
              </P>
            </ListItem>

            <ListItem>
              <P>
                Other than the third parties listed below, Tribally Games will
                not disclose your personal information to any third party unless
                either we have your permission to do so, or Tribally Games is,
                or consider itself to be, under a legal or regulatory obligation
                to do so.
                <br />
                <br />
                Your personal information may be disclosed to the following
                recipients, though Tribally Games will use its reasonable
                endeavours to control the use of your personal information.
              </P>
            </ListItem>
            <List>
              <ListItem>
                <P>
                  To our partners and Third-party service providers for the
                  purposes of carrying out services on our behalf (including
                  those used by Tribally Games to complete your orders for
                  products and/or services);
                </P>
              </ListItem>
              <ListItem>
                <P>
                  Any person to whom Tribally Games proposes to transfer any of
                  its rights and obligations under any agreement Tribally Games
                  has with you;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  To financial institutions or service providers who may request
                  the information in connection with providing services to us,
                  including banking services;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  To law enforcement or other legal or regulatory bodies as may
                  be permitted or required by the laws of any jurisdiction that
                  may apply to us as;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  To our employees where required or necessary for the
                  performance of their duties and in line with the reasons for
                  processing;
                </P>
              </ListItem>
              <ListItem>
                <P>To advisors and Tribally Games Agents; and</P>
              </ListItem>
              <ListItem>
                <P>To our professional advisers and external auditors.</P>
              </ListItem>
            </List>

            <ListItem>
              <P>
                We do not sell, rent, or otherwise share personal information
                that reasonably identifies you with unaffiliated entities for
                their independent use except as expressly described in this
                Notice or with your prior permission. We may share information
                that does not reasonably identify you as permitted by applicable
                law.
              </P>
            </ListItem>
          </List>

          <H2>Grounds for using your personal information</H2>

          <List>
            <ListItem>
              <P>
                Necessity for performance of a contract – We may need to collect
                and use your personal information to enter into a contract with
                you or to perform a contract that you have with us, for example,
                when you purchase Tokens;
              </P>
            </ListItem>
            <ListItem>
              <P>
                Legal obligation – we may process your personal information as
                we may be required to comply with any legal or regulatory
                obligations that may apply to us, for example under Anti-Money
                Laundering requirements;
              </P>
            </ListItem>
            <ListItem>
              <P>
                Legitimate Interests - We may use your personal information for
                our legitimate business interests to improve our Services; and
              </P>
            </ListItem>
            <ListItem>
              <P>
                Consent – Where required by applicable laws, we will reply on
                your consent for direct marketing and to collect information
                from your device or computer.
              </P>
            </ListItem>
          </List>

          <H2>Third party services and content</H2>

          <List>
            <ListItem>
              <P>
                Our Website may include integrated content or links to content
                provided by third parties (i.e. video materials, social media
                content, plug-ins).
              </P>
            </ListItem>
            <ListItem>
              <P>
                This Notice does not cover links within the Website to other
                third-party websites. Tribally Games is not responsible for the
                use of any personal information including without limitation
                cookies which you give directly to such third parties, or which
                third party websites may collect about you (using cookies or
                otherwise) while you visit pages hosted by them. Please refer to
                these third-party websites’ respective privacy policies for
                information about their use of your personal information and
                their use of cookies.
              </P>
            </ListItem>
          </List>

          <H2>
            Data Export, Protection, Retention and Storage of Information we
            collect
          </H2>

          <List>
            <ListItem>
              <P>
                Tribally Games understands the importance of ensuring its
                systems are secure from unauthorised access, use or disclosure
                so that emails and forms that contain your personal information
                are safe. We have administrative, technical and physical
                safeguards designed to comply with applicable legal requirements
                and to safeguard the information we collect. We also have
                internal policies, procedures and controls in place to ensure
                this, of both a technical and organisational nature.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We may store the information we collect on our servers both
                cloud based or in servers located in countries where our
                third-party service providers have facilities. To facilitate our
                global operations, Tribally Games may transfer your personal
                information outside of the European Economic Area (“EEA”), UK
                and Switzerland. The EEA includes the European Union countries
                as well as Iceland, Liechtenstein, and Norway. Transfers outside
                of the EEA are sometimes referred to as “third country
                transfers”. We may transfer information to countries outside of
                your residence which may have different data protection laws and
                regulations that differ from those in your country.
              </P>
            </ListItem>
            <ListItem>
              <P>
                For the purposes outlined in this Notice, Tribally Games may
                send your personal information (which includes transfers to
                other Tribally Affiliates) internationally, including to
                countries outside the EEA. However, your personal information
                will only be transferred to or from countries outside the EEA as
                long as those countries have compatible data protection laws and
                measures in place or where we have put in place adequate
                safeguards as approved by the European Commission, details of
                which will be made available on request.
              </P>
            </ListItem>
            <ListItem>
              <P>
                We retain your data primarily to meet statutory and regulatory
                obligations. Secondly, your data is retained to enable us to
                pursue our legitimate business interests to our clients, current
                and future requirements.
              </P>
            </ListItem>
          </List>

          <H2>Your Rights Explained</H2>

          <List>
            <ListItem>
              <P>
                GDPR gives you a number of rights regarding your personal
                information that is processed by us:
              </P>
            </ListItem>
            <List>
              <ListItem>
                <P>
                  The right to be informed: our obligation to provide fair
                  processing information;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  The right of access: allows you to be aware of and verify the
                  lawfulness of the processing;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  The right to rectification: allows you to request the data is
                  rectified if it is inaccurate or incomplete;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  The right to erasure: allows you to request the deletion or
                  removal of personal data where there is no compelling reason
                  for its continued processing;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  The right to restrict processing: allows you to ‘block’ or
                  suppress processing of personal data in certain circumstances;
                </P>
              </ListItem>
              <ListItem>
                <P>
                  The right to data portability: allows you to obtain and reuse
                  your personal data for your own purposes across different
                  services; and
                </P>
              </ListItem>
              <ListItem>
                <P>
                  The right to object: you must have an objection on grounds
                  relating to your particular situation.
                </P>
              </ListItem>
            </List>
            <ListItem>
              <P>
                To withdraw your consent to the use of your information as
                described in this Notice, you can contact us through the stated
                methods below. Please note that in some circumstances this may
                mean that we can no longer provide our Services to you.
              </P>
            </ListItem>
          </List>

          <H2>Keeping your personal information accurate and up-to-date.</H2>

          <List>
            <ListItem>
              <P>
                It is important that any personal information we hold on you is
                accurate and up-to-date. Please keep us informed if your
                personal information changes or needs updating or if you believe
                that any information we have collected on you is inaccurate or
                out of date.
              </P>
            </ListItem>
            <ListItem>
              <P>
                You can also request to see what personal information we hold on
                you and request to erase any personal information it if is no
                longer required. You may also inform us if you object to our use
                of your personal information.
              </P>
            </ListItem>
          </List>

          <H2>Opting-out of Email and Marketing Communication</H2>

          <List>
            <ListItem>
              <P>
                From time to time, we issue email communications about service
                updates, operational and product information, and industry news.
                The electronic marketing communications we send to you also
                contain opt-out mechanisms that allow you to opt-out from
                receiving those communications, update your contract information
                or change your preferences at any time. We will only send you
                the email communications you have registered for. You may also
                opt back in to receive these email communications at any time.
              </P>
            </ListItem>
            <ListItem>
              <P>
                If you no longer wish to receive marketing communication from
                us, you can let us know via the stated methods below.
              </P>
            </ListItem>
          </List>

          <H2>Queries or Complaints</H2>

          <List>
            <ListItem>
              <P>
                This privacy Notice does not provide exhaustive detail of all
                aspects of Tribally Games processing of personal information.
                However, Tribally Games will to provide any additional
                information or explanation needed, if requested.
              </P>
            </ListItem>
            <ListItem>
              <P>
                Tribally Games tries to meet the highest standards when
                processing personal information. For this reason, we take any
                complaints we receive about this very seriously. Tribally Games
                encourages people to bring it to its attention if they think
                that its collection or use of information is unfair, misleading
                or inappropriate.
              </P>
            </ListItem>
            <ListItem>
              <P>
                If you would like to exercise any of these choices, need further
                details or information, have a query or have a compliant about
                our use of your personal information, please contact:
              </P>
            </ListItem>
            <List>
              <ListItem>
                <P>By E-mail: support@tribally.games</P>
              </ListItem>
            </List>
          </List>

          <H2>Changes to our Notice</H2>

          <List>
            <ListItem>
              <P>
                We review our Notice regularly as our business evolves and where
                the Notice has been updated and this results in a change to this
                Notice, this will be clearly identified by reference to the date
                of this Notice above. Unless stated otherwise, our current
                Privacy Notice applies to all information that we have about you
                and your account.
              </P>
            </ListItem>
          </List>

          <H2>Annex 1 – Tribally Games and Affiliates</H2>
          <List>
            <ListItem>
              <P>Tribally Ltd BVI</P>
            </ListItem>
            <ListItem>
              <P>Tribally Studios Ltd</P>
            </ListItem>
            <ListItem>
              <P>Axie Tech</P>
            </ListItem>
            <ListItem>
              <P>Welcome</P>
            </ListItem>
            <ListItem>
              <P>Sipher Tech</P>
            </ListItem>
            <ListItem>
              <P>Pixelverse Tech</P>
            </ListItem>
            <ListItem>
              <P>Pegaxy Tech</P>
            </ListItem>
            <ListItem>
              <P>Imperium Empires Tech</P>
            </ListItem>
          </List>
        </Section>
      </Stack>
    </Page>
  );
};

export default PrivacyPolicy;
