import React, { useState, useEffect } from "react";
import {
  Container,
  Toolbar,
  IconButton,
  Drawer,
  AppBar,
  List,
  ListItem,
  ListItemText,
  Stack,
  Box,
  Typography,
} from "@mui/material";
import Logo from "./Logo";
import { CgMenuGridR } from "react-icons/cg";
import { useTheme } from "@mui/system";
import Socials from "../Socials/Socials";
import { useLocation, Link as RouterLink } from "react-router-dom";

const Navigation = ({ title }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const links = [{ text: "Characters", to: "/" }];

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 600
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const getLinks = () => {
    const activeStyles = {
      background: theme.palette.colors.gradient,
      "-webkitBackgroundClip": "text",
      "-webkitTextFillColor": "transparent",
    };
    return (
      <List
        sx={{
          display: "flex",
          flexDirection: mobileView ? "column" : "row",
          gap: 3,
          "& .MuiListItem-root": {
            color: theme.palette.colors.black900,
            position: "relative",
            textAlign: "center",
            px: 1,
            p: 0,
            background: "transparent",
            "&.Mui-selected": {
              background: "transparent",
              ".MuiListItemText-root": {
                ...activeStyles,
              },
              "&:hover": {
                background: "transparent",
              },
            },
            "&:hover": {
              background: "transparent",
              ".MuiListItemText-root": {
                ...activeStyles,
              },
            },
            "& .MuiListItemText-root": {
              my: 0,
            },
          },
        }}
      >
        {links.map(({ text, to }) => {
          return (
            <ListItem
              button
              component={RouterLink}
              to={to}
              selected={
                to === pathname ||
                (to !== "/" &&
                  pathname.split("/").includes(to.replace("/", "")))
              }
              key={`NAV_ITEM_${to}`}
              disableGutters
              disableRipple
            >
              <ListItemText
                primary={text}
                primaryTypographyProps={{
                  fontWeight: 600,
                  variant: "body2",
                  fontSize: 20,
                  color: "inherit",
                  whiteSpace: "nowrap",
                  fontFamily: "Koulen, sans-serif",
                  mb: 0,
                }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  const displayDesktop = () => {
    return (
      <Toolbar disableGutters sx={{ justifyContent: "space-between", py: 1 }}>
        <Logo />
        <Stack direction="row" gap={3} alignItems="center">
          {getLinks()}
          <Socials color={theme.palette.colors.black900} />
        </Stack>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar
        disableGutters
        sx={{
          justifyContent: "space-between",
          py: 1,
        }}
      >
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          sx={{
            "& .MuiPaper-root": {
              gap: 4,
              alignItems: "flex-start",
              py: 6,
              px: 2,
            },
          }}
        >
          <Logo style={{ mb: 4 }} />
          {getLinks()}
          <Socials color={theme.palette.colors.black900} />
        </Drawer>

        <Logo />

        <IconButton
          {...{
            edge: "start",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
          sx={{ svg: { color: theme.palette.colors.black900 } }}
        >
          <CgMenuGridR />
        </IconButton>
      </Toolbar>
    );
  };

  return (
    <Stack>
      <Box
        sx={{
          height: 200,
          width: "100%",
          backgroundImage: 'url("/images/sipher/environment/enviroment1.jpg")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      />

      <Container sx={{ mt: -6, position: "relative" }}>
        <Typography
          fontSize={80}
          variant="span"
          fontFamily="Koulen, sans-serif"
          sx={{
            textTransform: "uppercase",
            lineHeight: 1,
            mb: "-15px",
            display: "block",
            position: "absolute",
            bottom: "100%",
            userSelect: "none",
            [theme.breakpoints.down("sm")]: {
              fontSize: 70,
            },
          }}
        >
          {title}
        </Typography>
        <AppBar
          position="static"
          sx={{ mb: -2, [theme.breakpoints.down("sm")]: { px: 2 } }}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </AppBar>
      </Container>
    </Stack>
  );
};
export default Navigation;
