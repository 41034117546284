export const TRIBALLY_SLOGAN = "PoweredBy Tribally Games";

export const SIPHER_TECH_TWITTER = "https://twitter.com/Sipher_Tech";
export const SIPHER_TECH_TWITTER_HANDLE = "@Sipher_Tech";
export const SIPHER_TECH_CDN = `https://${process.env.REACT_APP_CDN || 'cdn.tribally.xyz'}/sipher`;

export const SIPHER_TECH_COMPANY_NAME = "Sipher Tech";
export const SIPHER_TECH_CONTACT_EMAIL = "info@sipher.tech";
export const SIPHER_TECH_TITLE = SIPHER_TECH_COMPANY_NAME;
export const SIPHER_TECH_URL = "https://sipher.tech";
export const SIPHER_TECH_DESCRIPTION =
    "Sipher Tech, the official tooling partner of Sipher, provides tools and resources for gamers and investors.";

export const SIPHER_SPECIES = ["Inu", "Neko", "Buru"];

export const OPENSEA_COLLECTIONS = {
    inu: {
        species: "Inu",
        name: "Sipherian Surge",
        url: "https://opensea.io/collection/sipheriansurge",
        collectionSize: 10000,
        rarity: {
            canis: 7000,
            bioz: 1500,
            cyborg: 1000,
            cosmic: 500,
        },
    },
    neko: {
        species: "Neko",
        name: "Sipherian Flash",
        url: "https://opensea.io/collection/sipherianflash",
        collectionSize: 10000,
        rarity: {
            felis: 7000,
            synthetic: 1500,
            phasewalker: 1000,
            crystalis: 500,
        },
    },
    buru: {
        species: "Buru",
        name: "Coming Soon!",
        url: undefined,
        rarity: undefined,
    },
};
