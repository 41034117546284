import React from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { OPENSEA_COLLECTIONS } from "config";
import { createOpenseaUrlForSubSpecies, displayPercent } from "helpers/display";

const Rarity = ({ species, subSpecies }) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const rarity =
    OPENSEA_COLLECTIONS[species.toLowerCase()].rarity[subSpecies.toLowerCase()];
  const collectionSize =
    OPENSEA_COLLECTIONS[species.toLowerCase()].collectionSize;
  const width = smDown ? 300 : lgDown ? 400 : 460;

  return (
    <Stack sx={{ width }}>
      <Box
        sx={{
          "&::after": {
            content: "''",
            display: "block",
            height: "1em",
            width: "100%",
            background: theme.palette.colors.secondary,
            position: "relative",
            top: "-0.3em",
            zIndex: 1,
          },
        }}
      >
        <Stack
          direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
          alignItems={{
            xs: "center",
            sm: "center",
            md: "center",
            lg: "flex-end",
          }}
          justifyContent={{
            xs: "flex-start",
            sm: "flex-start",
            md: "space-between",
          }}
          sx={{
            position: "relative",
            zIndex: 2,
            [theme.breakpoints.down("lg")]: {
              mb: 1,
            },
          }}
        >
          <Stack
            alignItems={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "flex-start",
            }}
          >
            <Typography
              variant="span"
              fontSize={20}
              fontStyle="italic"
              textTransform="uppercase"
              marginBottom="-8px"
            >
              Genesis
            </Typography>
            <Typography variant="h3" fontSize={32}>
              {species} {subSpecies} Rarity
            </Typography>
          </Stack>
          <Typography
            variant="span"
            color="text.bright"
            fontSize={40}
            fontWeight={800}
            sx={{ lineHeight: 1 }}
          >
            {displayPercent(rarity / collectionSize)}
          </Typography>
        </Stack>
      </Box>

      <Stack sx={{ background: "#fff", p: 2 }}>
        <Row label={`${species} ${subSpecies} NFTs:`} value={rarity} />
        <Row label={`Total ${species} NFTs:`} value={collectionSize} />
      </Stack>
      <Triangle width={width} />

      <Button
        href={createOpenseaUrlForSubSpecies(species, subSpecies)}
        target="_blank"
        endIcon={<MdOutlineDoubleArrow />}
        sx={{ alignSelf: "flex-start" }}
      >
        Browse Market
      </Button>
    </Stack>
  );
};

const Triangle = ({ width }) => (
  <Box
    sx={{
      width: 0,
      height: 0,
      borderTop: `${(20 / 400) * width}px solid #fff`,
      borderLeft: `${width}px solid transparent`,
    }}
  />
);

const Row = ({ label, value }) => (
  <Stack direction="row" justifyContent="space-between" alignItems="center">
    <Typography
      variant="span"
      color="text.dark"
      fontWeight={800}
      fontSize={{ xs: 17, sm: 17, md: 20 }}
      textTransform="uppercase"
      sx={{ lineHeight: 1.1 }}
    >
      {label}
    </Typography>
    <Typography
      variant="span"
      color="text.primary"
      fontWeight={800}
      fontSize={28}
    >
      {value.toLocaleString()}
    </Typography>
  </Stack>
);

export default Rarity;
