import React from "react";
import SectionHeader from "../atoms/SectionHeader";
import TextFromArray from "../atoms/TextFromArray";
import { speciesBios } from "data/characters";
import { Stack } from "@mui/material";

const Species = ({ species }) => {
  return (
    <Stack>
      <SectionHeader>The {species} Species</SectionHeader>
      <TextFromArray arr={speciesBios[species.toLowerCase()]} />
    </Stack>
  );
};

export default Species;
