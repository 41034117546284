import React from "react";
import CustomLink from "../CustomLink/CustomLink";
import { Stack } from "@mui/material";

const Logo = ({ style }) => {
  return (
    <Stack
      sx={{
        img: {
          width: 170,
        },
        ...style,
        a: {
          display: "flex",
        },
      }}
    >
      <CustomLink to="/" underline="none">
        <img src="/images/sipher-tech-logo.png" alt="Sipher Tech Logo" />
      </CustomLink>
    </Stack>
  );
};

export default Logo;
