import React from "react";
import { Box } from "@mui/material";

const ClippedWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        filter: "drop-shadow(10px 10px 10px rgba(149, 99, 255, 0.38))",
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          position: "relative",
          paddingTop: "56.25%",
          clipPath: "polygon(0 0, 100% 0, 100% 100%, 66% 92%, 63% 100%, 0 90%)",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ClippedWrapper;
