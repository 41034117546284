import { Stack } from "@mui/material";
import React from "react";
import SectionHeader from "./SectionHeader";
import TextFromArray from "./TextFromArray";

const Bio = ({ bio }) => {
  return (
    <Stack>
      <SectionHeader>Profile</SectionHeader>
      <TextFromArray arr={bio} />
    </Stack>
  );
};

export default Bio;
