import React from "react";
import { Typography } from "@mui/material";
import HeadingUnderlined from "components/Typography/HeadingUnderlined";
import NarrowWidth from "../atoms/NarrowWidth";

const Intro = () => {
  return (
    <NarrowWidth>
      <HeadingUnderlined>Characters</HeadingUnderlined>
      <Typography variant="body1">
        Welcome to the world of Sipheria! Choose from a variety of playable
        characters, each with randomized traits that make them completely unique
        from one another. Each Sipher character belongs to a species and
        sub-race, wears distinctive armor and wields a specific weapon.
      </Typography>
    </NarrowWidth>
  );
};

export default Intro;
