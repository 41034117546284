import React from "react";
import { Box } from "@mui/material";
import ComingSoon from "../ComingSoon";

const Maps = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: 240,
        img: {
          maxHeight: "100%",
        },
        ".bg": { position: "relative", left: "9.3%", top: "13.4%" },
        ".showcase": {
          position: "absolute",
          left: 0,
          top: 0,
          transition: "all .8s cubic-bezier(.215,.61,.355,1)",
          maxHeight: "113%",
        },
        "&:hover": {
          cursor: "pointer",
          ".showcase": { left: "5.1%", top: "7%" },
          ".comingSoon": {
            opacity: 1,
            transform: "scale(1) rotate(15deg)",
          },
        },
      }}
    >
      <ComingSoon
        styles={{
          transform: "scale(0.2) rotate(0deg)",
          right: "-6%",
          bottom: "24%",
        }}
      />
      <img
        className="bg"
        src="/images/explore/maps-bg.png"
        alt="Explore Maps"
      />

      <img
        className="showcase"
        src="/images/explore/maps.png"
        alt="Explore Maps"
      />
    </Box>
  );
};

export default Maps;
