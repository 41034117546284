import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Maps from "./Maps/Maps";
import Weapons from "./Weapons/Weapons";

const Explore = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack gap={4}>
      <Stack alignItems="center">
        <Typography
          variant="span"
          fontFamily="Koulen, sans-serif"
          fontSize={32}
          textAlign="center"
          sx={{ borderBottom: "8px solid #fff" }}
        >
          Explore
        </Typography>
      </Stack>

      {isMobile ? (
        <Stack
          direction={{ xs: "column", sm: "row", md: "row" }}
          gap={2}
          justifyContent={{ xs: "flex-start", sm: "center", md: "center" }}
          alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
        >
          <ResponsiveImage>
            <img src="/images/explore/weapons-mobile.png" alt="Weapons" />
          </ResponsiveImage>
          <ResponsiveImage>
            <img src="/images/explore/maps-mobile.png" alt="Maps" />
          </ResponsiveImage>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="center">
          <Weapons />
          <Maps />
        </Stack>
      )}
    </Stack>
  );
};

const ResponsiveImage = ({ children }) => (
  <Box sx={{ img: { maxWidth: "100%" } }}>{children}</Box>
);

export default Explore;
