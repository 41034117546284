import React from "react";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import CustomLink from "components/CustomLink/CustomLink";

const Wrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "width",
})(({ theme, width }) => ({
  maxWidth: 250,
  width,
  a: { display: "flex" },
}));

const TriballyBadge = ({ width, title, alt }) => {
  return (
    <Wrapper width={width}>
      <CustomLink underline="none" href="https://tribally.games/">
        <img
          style={{ alignSelf: "center", justifySelf: "center" }}
          width={width}
          src={`/images/tribally-assets/powered-by-tribally-badge.png`}
          title={title}
          alt={alt}
        />
      </CustomLink>
    </Wrapper>
  );
};

export default TriballyBadge;
