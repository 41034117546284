import React from "react";
import { Box } from "@mui/material";
import ComingSoon from "../ComingSoon";

const Weapons = () => {
  return (
    <Box
      sx={{
        position: "relative",
        height: 240,
        mr: -6,
        img: {
          maxHeight: "100%",
        },
        ".showcase": {
          position: "absolute",
          left: 0,
          top: 0,
          transition: "all .8s cubic-bezier(.215,.61,.355,1)",
          maxHeight: "113%",
        },
        "&:hover": {
          cursor: "pointer",
          ".showcase": { left: "-2.5%", top: "-6.5%" },
          ".comingSoon": {
            opacity: 1,
            transform: "scale(1) rotate(-15deg)",
          },
        },
      }}
    >
      <ComingSoon
        styles={{
          transform: "scale(0.2) rotate(0deg)",
          left: "16%",
          bottom: "38%",
        }}
      />
      <img src="/images/explore/weapons-bg.png" alt="Explore Weapons" />

      <img
        className="showcase"
        src="/images/explore/weapons.png"
        alt="Explore Weapons"
      />
    </Box>
  );
};

export default Weapons;
