import React from "react";
import { Stack, Typography } from "@mui/material";
import { characters } from "data/characters";
import BackButton from "./atoms/BackButton";
import Jumbotron from "./molecules/Jumbotron";
import Profile from "./organisms/Profile";

const Character = ({ id }) => {
  const character = characters.find((c) => c.id === id);

  return character == null ? (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "calc(100vh - 532px)" }}
    >
      <Stack gap={2} alignItems="center">
        <Typography variant="h2">Hmm, we can't find that character.</Typography>
        <BackButton />
      </Stack>
    </Stack>
  ) : (
    <Stack gap={4}>
      <Jumbotron character={character} />
      <Profile character={character} />
    </Stack>
  );
};

export default Character;
