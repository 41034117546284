import React from "react";
import { Button, useTheme } from "@mui/material";

const FilterButton = ({ button, filter, setFilter }) => {
  const theme = useTheme();
  const isActive = button === filter;

  return (
    <Button
      variant="contained"
      onClick={() => setFilter(button)}
      sx={{
        background: isActive ? theme.palette.colors.gradient : "#fff",
        color: isActive ? "#fff" : theme.palette.colors.black900,
        "&:hover": {
          background: theme.palette.colors.gradient,
          color: "#fff",
        },
      }}
    >
      {button}
    </Button>
  );
};

export default FilterButton;
