import { Stack } from "@mui/material";
import React from "react";
import { characters } from "data/characters";
import Character from "../molecules/Character";

const CharacterGallery = ({ filter }) => {
  return (
    <Stack
      direction={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "center", sm: "flex-start" }}
      justifyContent={{ xs: "flex-start", sm: "center" }}
      flexWrap="wrap"
      gap={2}
    >
      {characters
        .filter((c) => c.species === filter || filter === "All")
        .map((character, i) => (
          <Character key={`CHARACTER_${i}`} character={character} />
        ))}
    </Stack>
  );
};

export default CharacterGallery;
