import React from "react";
import { Stack, useTheme } from "@mui/material";

import Label from "components/Typography/Label";
import FilterButton from "../atoms/FilterButton";

const FilterButtons = ({ filter, setFilter }) => {
  const filters = ["All", "Inu", "Neko", "Buru"];
  const theme = useTheme();

  return (
    <Stack gap={2}>
      <Label>Explore Species:</Label>
      <Stack
        direction="row"
        sx={{
          [theme.breakpoints.down("sm")]: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "16px",
          },
        }}
      >
        {filters.map((button, i) => (
          <FilterButton
            key={`FILTER_${i}`}
            button={button}
            filter={filter}
            setFilter={setFilter}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default FilterButtons;
