import React from "react";
import { MdOutlineDoubleArrow } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Button } from "@mui/material";

const BackButton = () => {
  return (
    <Button
      to="/characters"
      component={RouterLink}
      size="large"
      startIcon={
        <Stack sx={{ transform: "rotate(180deg)" }}>
          <MdOutlineDoubleArrow />
        </Stack>
      }
      variant="outlined"
      sx={{ position: "relative", zIndex: 4 }}
    >
      All Characters
    </Button>
  );
};

export default BackButton;
