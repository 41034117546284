import { OPENSEA_COLLECTIONS } from "../config";

export const capitalizeWord = (word) =>
  word.charAt(0).toUpperCase() + word.slice(1);

export const prettifySlug = (slug) => {
  if (slug == null) {
    return null;
  }
  const arr = slug.split("-");
  const words = arr.map((word) => capitalizeWord(word));
  return words.join(" ");
};

export const createCharacterName = (species, subspecies) =>
  species + " " + subspecies;

export const displayPercent = (x) => {
  return (
    (x * 100).toLocaleString(undefined, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }) + "%"
  );
};

export const createOpenseaUrlForSubSpecies = (species, subSpecies) => {
  const base = OPENSEA_COLLECTIONS[species.toLowerCase()].url;

  return (
    base +
    `?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=sub-race&search[stringTraits][0][values][0]=${subSpecies}`
  );
};
