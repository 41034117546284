import { Typography } from "@mui/material";
import React from "react";

const HeadingUnderlined = ({ children, variant = "h1" }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        mb: 1,
        "&::after": {
          content: "''",
          display: "block",
          height: 10,
          width: "100%",
          background: "#fff",
          position: "relative",
          top: -15,
        },
      }}
    >
      {children}
    </Typography>
  );
};

export default HeadingUnderlined;
