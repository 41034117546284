import React from "react";
import { Stack } from "@mui/material";
import NamePlate from "components/Character/NamePlate";
import { createCharacterName } from "helpers/display";
import LinkedWrapper from "../atoms/LinkedWrapper";
import CharacterImage from "../atoms/CharacterImage";

const Character = ({ character, width = 276 }) => {
  return (
    <LinkedWrapper id={character.id}>
      <Stack
        alignItems="center"
        sx={{
          width,
          background:
            "linear-gradient(180deg, #dc4664 0%, #EE7847 12.76%, #FFFFFF 100%)",
          overflow: "hidden",
          ".characterImage": {
            transition: "transform .8s cubic-bezier(.215,.61,.355,1)",
            width: "100%",
          },
          "&:hover": {
            ".characterImage": {
              transform: "scale(1.1)",
            },
          },
        }}
      >
        <CharacterImage
          slug={character.id}
          name={createCharacterName(character.species, character.subSpecies)}
        />
        <NamePlate
          species={character.species}
          subSpecies={character.subSpecies}
          width={width}
        />
      </Stack>
    </LinkedWrapper>
  );
};

export default Character;
