import {
  SIPHER_TECH_DESCRIPTION,
  SIPHER_TECH_TWITTER_HANDLE,
  SIPHER_TECH_URL,
  SIPHER_TECH_TITLE,
} from "config";
import React from "react";
import Helmet from "react-helmet";

const SEO = ({
  seoTitle = SIPHER_TECH_TITLE,
  seoDescription = SIPHER_TECH_DESCRIPTION,
}) => {
  return (
    <Helmet>
      {/* General tags */}
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <meta
        name="keywords"
        content="sipher tech, sipher.tech, sipher, nft gaming, crypto gaming, web3, gaming"
      />
      {/* OpenGraph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SIPHER_TECH_URL} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta
        property="og:image"
        content="https://sipher.tech/images/social/facebook.jpg"
      />
      <meta property="og:site_name" content={SIPHER_TECH_TITLE} />
      {/* Twitter Card tags */}
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta
        name="twitter:image"
        content="https://sipher.tech/images/social/twitter.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={SIPHER_TECH_TWITTER_HANDLE} />
      <meta name="twitter:creator" content={SIPHER_TECH_TWITTER_HANDLE} />
    </Helmet>
  );
};

export default SEO;
