import React from "react";
import { Typography } from "@mui/material";

const Label = ({ children }) => {
  return (
    <Typography variant="span" fontWeight={800} textTransform="uppercase">
      {children}
    </Typography>
  );
};

export default Label;
