import React from "react";
import { Typography, Stack } from "@mui/material";

const Skill = ({ skill }) => (
  <Stack
    direction="row"
    gap={4}
    alignItems="flex-start"
    sx={{
      img: {
        width: 48,
      },
    }}
  >
    <img src="/images/character/skill-icon.png" alt="Skill icon" />
    <Stack>
      <Typography variant="h3" component="h4">
        {skill.name}
      </Typography>
      <Typography variant="body2">{skill.effect}</Typography>
    </Stack>
  </Stack>
);

export default Skill;
