import React from "react";
import { Stack } from "@mui/material";
import SectionHeader from "../atoms/SectionHeader";
import { characters } from "data/characters";
import Character from "pages/Characters/molecules/Character";

const Family = ({ species }) => {
  const members = characters.filter((c) => c.species === species);
  return (
    <Stack>
      <SectionHeader>{species} Sub-Races</SectionHeader>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {members != null &&
          members.map((member, i) => (
            <Character character={member} key={`MEMBER_${i}`} width={200} />
          ))}
      </Stack>
    </Stack>
  );
};

export default Family;
