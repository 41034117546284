import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/styles.scss";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { StyledEngineProvider } from "@mui/material/styles";
import CustomRoutes from "./CustomRoutes";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  const customTheme = createTheme(theme);

  return (
    <ThemeProvider theme={customTheme}>
      <StyledEngineProvider injectFirst>
        <Router>
          <CssBaseline />
          <CustomRoutes />
        </Router>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
