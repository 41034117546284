import React, { useState } from "react";
import ReactPlayer from "react-player/lazy";
import { SIPHER_TECH_CDN } from "config";

const Video = ({ id, videoLoaded, setVideoLoaded }) => {
  const [playing, setPlaying] = useState(false);
  const handleOnProgress = (loaded) => {
    if (loaded === 1) {
      setVideoLoaded(true);
      setPlaying(true);
    }
  };
  return (
    <ReactPlayer
      url={`${SIPHER_TECH_CDN}/${id}.mp4`}
      width="100%"
      height="100%"
      volume={0}
      playing={playing}
      loop={true}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        opacity: videoLoaded ? 1 : 0,
      }}
      onProgress={({ loaded }) => handleOnProgress(loaded)}
    />
  );
};

export default Video;
